import type { VueInstance } from "@vueuse/core";
import { computed, ref, type Ref } from "vue";

const focusableElementsSelector =
  "button:not([tabindex='-1']), [href]:not([tabindex='-1']), input:not([tabindex='-1']), select:not([tabindex='-1']), textarea:not([tabindex='-1']), [tabindex]:not([tabindex='-1'])";

export const useFocusableElements = (
  targetElement: Ref<HTMLElement | SVGElement | VueInstance | undefined>,
) => {
  const focusableElements = ref<Element[]>();

  function isVisible(element: Element): boolean {
    let parentVisible = true;
    if (element.parentElement) {
      parentVisible = isVisible(element.parentElement);
    }
    return (
      parentVisible &&
      window.getComputedStyle(element).display !== "none" &&
      window.getComputedStyle(element).visibility !== "hidden"
    );
  }

  const visibleFocusableElements = computed(() => {
    if (focusableElements.value) {
      return focusableElements.value.filter((element) => {
        return isVisible(element);
      });
    }
    return [];
  });

  const firstFocusableElement = computed(() => {
    if (
      visibleFocusableElements.value &&
      visibleFocusableElements.value.length > 0 &&
      isHtmlOrSvgForElement(visibleFocusableElements.value[0])
    ) {
      return visibleFocusableElements.value[0];
    }
    return undefined;
  });

  const lastFocusableElement = computed(() => {
    if (
      visibleFocusableElements.value &&
      visibleFocusableElements.value.length > 0
    ) {
      const lastElement =
        visibleFocusableElements.value[
          visibleFocusableElements.value.length - 1
        ];
      if (isHtmlOrSvgForElement(lastElement)) {
        return lastElement;
      }
    }
    return undefined;
  });

  function isVueInstance(
    value: HTMLElement | SVGElement | VueInstance | undefined,
  ): value is VueInstance {
    return value !== undefined && (value as VueInstance).$el !== undefined;
  }

  function isHtmlOrSvgForElement(
    value: Element | undefined,
  ): value is HTMLElement | SVGElement {
    return (
      value !== undefined &&
      (value as HTMLElement | SVGElement).focus !== undefined
    );
  }

  function isHtmlOrSvg(
    value: HTMLElement | SVGElement | VueInstance | undefined,
  ): value is HTMLElement | SVGElement {
    return (
      value !== undefined &&
      (value as HTMLElement | SVGElement).querySelectorAll !== undefined
    );
  }

  function updateFocusableElements() {
    const targetElementHtml = getTargetElementHtml();
    if (targetElementHtml) {
      focusableElements.value = Array.from(
        targetElementHtml.querySelectorAll(focusableElementsSelector),
      );
    }
  }
  function getTargetElementHtml() {
    if (!targetElement.value) {
      return;
    }
    let targetElementHtml: HTMLElement | SVGElement | undefined = undefined;
    if (isVueInstance(targetElement.value)) {
      targetElementHtml = targetElement.value.$el;
    }
    if (isHtmlOrSvg(targetElement.value)) {
      targetElementHtml = targetElement.value;
    }
    return targetElementHtml;
  }

  return {
    firstFocusableElement,
    lastFocusableElement,
    focusableElements,
    visibleFocusableElements,
    updateFocusableElements,
  };
};
