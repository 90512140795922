<template>
  <label
    :for="props.for"
    class="flex items-start font-medium before:invisible before:mr-0.5 before:text-supporting-12 before:content-['*'] data-disabled:text-neutral-4 data-required:before:visible"
    :data-required="undefinedIfNotTrue(required)"
    :data-disabled="undefinedIfNotTrue(disabled)"
  >
    <slot></slot>
  </label>
</template>
<script setup lang="ts">
import { undefinedIfNotTrue } from "../composables/attributes";

interface Props {
  for?: string;
  required?: boolean;
  disabled?: boolean;
}

const props = defineProps<Props>();
</script>
