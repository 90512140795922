export const TIME_REGEXP = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
export const DATE_REGEXP = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

function normalize(text: string) {
  return text
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLocaleLowerCase();
}

function escapeRegex(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function predicate(
  item: Record<string | number, unknown>,
  keys: string[],
  matchPredicate: (item: string) => boolean,
) {
  const filterItems = Object.entries(item).filter((entry) =>
    keys.includes(entry[0]),
  );
  for (let i = 0; i < filterItems.length; i++) {
    const filterValue = filterItems[i][1];
    if (
      typeof filterValue === "boolean" ||
      typeof filterValue === "number" ||
      typeof filterValue === "string"
    ) {
      const matches = matchPredicate(filterValue.toString());
      if (matches) {
        return true;
      }
    }
  }
  return false;
}

function filter<T extends Record<string | number, unknown>>(
  list: T[],
  listKeys: string[],
  matchPredicate: (item: string) => boolean,
): T[] {
  return list.filter((item) => predicate(item, listKeys, matchPredicate));
}

function find(
  list: Record<string | number, unknown>[],
  listKeys: string[],
  matchPredicate: (item: string) => boolean,
) {
  return list.find((item) => predicate(item, listKeys, matchPredicate));
}

export function filterContains<T extends Record<string | number, unknown>>(
  searchString: string,
  list: T[],
  listKeys: string[],
): T[] {
  const regex = new RegExp(escapeRegex(normalize(searchString)));
  return filter(list, listKeys, (item) => {
    return regex.test(normalize(item));
  });
}

export function filterStartsWith(
  searchString: string,
  list: Record<string | number, unknown>[],
  listKeys: string[],
) {
  return filter(list, listKeys, (item) =>
    normalize(item).startsWith(normalize(searchString)),
  );
}

export function findStartsWith(
  searchString: string,
  list: Record<string | number, unknown>[],
  listKeys: string[],
  ignoreSpaces = false,
) {
  if (ignoreSpaces) {
    return find(list, listKeys, (item) =>
      normalize(item).replace(/\s/g, "").startsWith(searchString),
    );
  }
  return find(list, listKeys, (item) =>
    normalize(item).startsWith(searchString),
  );
}

export function conditionConcatenate(
  stringList: string[],
  conditionList: boolean[],
  separator: string,
) {
  if (stringList.length !== conditionList.length) {
    throw new Error("stringList and conditionList should be the same length");
  }
  let resultString = "";
  for (let i = 0; i < stringList.length; i++) {
    if (conditionList[i]) {
      if (resultString !== "") {
        resultString += separator;
      }
      resultString += stringList[i];
    }
  }

  return resultString;
}
export function formatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replaceAll(`%${index + 1}$s`, "%s");
    str = str.replace(`%s`, val[index]);
  }
  return str;
}

export function isValidNumber(input: string) {
  const parsedInputTextfieldValue = input.replace(",", ".");
  const inputNumberValue = parseFloat(parsedInputTextfieldValue);
  if (isNaN(inputNumberValue)) {
    return false;
  }

  return true;
}

export function toNumberOrUndefined(
  input: string | undefined,
): number | undefined {
  if (input && isValidNumber(input)) {
    return parseFloat(input.replace(",", "."));
  }

  return undefined;
}

export function formatWithDecimalPlaces(input: string, decimalPlaces: number) {
  if (isValidNumber(input)) {
    return Number(
      parseFloat(input.replace(",", ".")).toFixed(decimalPlaces),
    ).toString();
  }
  return input;
}

export function normalizedEqual(string1: string, string2: string) {
  return normalize(string1).trim() === normalize(string2).trim();
}

export function parseTime(time: string): [number, number] | undefined {
  if (!TIME_REGEXP.test(time)) {
    return undefined;
  }
  const timeParts = time.split(":");
  return [parseInt(timeParts[0]), parseInt(timeParts[1])];
}

export function formatTimestamp(timestamp: string) {
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
}

export function dateWithoutTime(dateString: string): Date {
  const date = new Date(dateString);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function replaceWhiteSpaces(
  str: string | number | null | undefined,
  replacement: string,
): string | undefined {
  if (str === undefined || str === null) {
    return undefined;
  }
  return str.toString().replace(/\s+/g, replacement);
}
