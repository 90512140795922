import type { ValidationResult } from "../types/formValidation";
import {
  DATE_REGEXP,
  TIME_REGEXP,
  parseTime,
  toNumberOrUndefined,
} from "./stringUtils";

export interface Localization {
  required: string;
  valueFromList: string;
  phone: string;
  zipcode: string;
  integerNumber: string;
  naturalNumber: string;
  nonNegativeNumber: string;
  positiveNumber: string;
  pesel: string;
  email: string;
  passwordMinTenCharacters: string;
  passwordLeastOneUppercaseLetter: string;
  passwordLeastOneLowercaseLetter: string;
  passwordLeastOneNumber: string;
  passwordLeastOneSpecialCharacter: string;
  login: string;
  youtube: string;
  maxCharacters: string;
  minCharacters: string;
  maxNumber: string;
  minNumber: string;
  time: string;
  date: string;
  minTime: string;
  maxTime: string;
  maxDecimalPlaces: string;
}

type InputType =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | undefined
  | null;

export type ValidationFunction<T extends InputType> = {
  (input: T, localization: Localization): ValidationResult;
};

export const defaultLocalization: Localization = {
  required: "Pole wymagane",
  valueFromList: "Wybierz wartość z listy",
  phone: "Wprowadź poprawny numer telefonu",
  zipcode: "Wprowadź poprawny kod pocztowy (xx-xxx)",
  integerNumber: "Wprowadź liczbę całkowitą",
  naturalNumber: "Wprowadź liczbę całkowitą dodatnią",
  nonNegativeNumber: "Wprowadź liczbę nieujemną",
  positiveNumber: "Wprowadź liczbę dodatnią",
  pesel: "Wprowadź poprawny numer PESEL",
  email: "Wprowadź poprawny adres e-mail",
  passwordMinTenCharacters: "Hasło musi składać się co najmniej z 10 znaków",
  passwordLeastOneLowercaseLetter:
    "Hasło musi zawierać co najmniej jedną małą literę",
  passwordLeastOneUppercaseLetter:
    "Hasło musi zawierać co najmniej jedną dużą literę",
  passwordLeastOneNumber: "Hasło musi zawierać co najmniej jedną cyfrę",
  passwordLeastOneSpecialCharacter:
    "Hasło musi zawierać co najmniej jeden znak specjalny",
  login:
    "Login musi składać się z co najmniej 8 znaków oraz zawierać wyłącznie litery lub/i cyfry",
  youtube: "Niepoprawny link",
  maxCharacters: "Wprowadź co najwyżej {0} znaków",
  minCharacters: "Wprowadź co najmniej {0} znaków",
  maxNumber: "Maksymalna wartość to {0}",
  minNumber: "Minimalna wartość to {0}",
  time: "Wprowadź poprawny czas (hh:mm)",
  date: "Wprowadź poprawną datę (yyyy-mm-dd)",
  minTime: "Wprowadź czas nie wcześniejszy niż {0}",
  maxTime: "Wprowadź czas nie późniejszy niż {0}",
  maxDecimalPlaces: "Wprowadź maksymalnie {0} miejsc po przecinku",
};

export function required(
  input: InputType,
  localization: Localization,
): ValidationResult {
  return {
    valid:
      input !== undefined &&
      input !== null &&
      input !== "" &&
      input !== false &&
      (!Array.isArray(input) || input.length > 0),
    message: localization.required,
  };
}

export function withValidators<T extends InputType>(
  validators: ValidationFunction<T>[],
  addValidators?: [ValidationFunction<T>, boolean][],
): ValidationFunction<T>[] {
  const result = [...validators];
  if (addValidators) {
    for (const [validator, add] of addValidators) {
      if (add && !validators.includes(validator)) {
        result.push(validator);
      }
    }
  }
  return result;
}

export function phone(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  if (input === null || input === undefined || input === "") {
    return { valid: true, message: "" };
  }
  return {
    valid: /^[+]?[0-9]{9,15}$/.test(input.toString().replace(/\s/g, "")),
    message: localization.phone,
  };
}

export function zipcode(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  if (input === null || input === undefined || input === "") {
    return { valid: true, message: "" };
  }
  return {
    valid: /^\d\d-\d\d\d$/.test(input.toString().trim()),
    message: localization.zipcode,
  };
}

export function integerNumber(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  if (input === null || input === undefined || input === "") {
    return { valid: true, message: "" };
  }
  return {
    valid: /^-?\d+$/.test(input.toString()),
    message: localization.naturalNumber,
  };
}

export function naturalNumber(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  if (input === null || input === undefined || input === "") {
    return { valid: true, message: "" };
  }
  return {
    valid: /^[0-9]*[1-9]+$|^[1-9]+[0-9]*$/.test(input.toString()),
    message: localization.naturalNumber,
  };
}

export function nonNegativeNumber(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  if (input === null || input === undefined || input === "") {
    return { valid: true };
  }
  if (typeof input === "boolean") {
    return { valid: false, message: localization.nonNegativeNumber };
  }
  const inputNumber = toNumberOrUndefined(input.toString());
  if (inputNumber !== undefined) {
    return { valid: inputNumber >= 0, message: localization.nonNegativeNumber };
  }
  return { valid: false, message: localization.nonNegativeNumber };
}

export function positiveNumber(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  if (input === null || input === undefined || input === "") {
    return { valid: true };
  }
  if (typeof input === "boolean") {
    return { valid: false, message: localization.positiveNumber };
  }
  const inputNumber = toNumberOrUndefined(input.toString());
  if (inputNumber !== undefined) {
    return { valid: inputNumber > 0, message: localization.positiveNumber };
  }
  return { valid: false, message: localization.positiveNumber };
}

export function pesel(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  const validResult = { valid: true, message: "" };
  if (input === null || input === undefined || input === "") {
    return validResult;
  }
  const invalidResult = {
    valid: false,
    message: localization.pesel,
  };
  if (!/^\d\d\d\d\d\d\d\d\d\d(\d|C)$/.test(input.toString())) {
    return invalidResult;
  }
  if (/^\d\d\d\d\d\d\d\d\d\dC$/.test(input.toString())) {
    return validResult;
  }

  const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += weights[i] * parseInt(input.toString().charAt(i));
  }
  let lastDigit = parseInt(input.toString().charAt(10));
  if (lastDigit === 0) {
    lastDigit = 10;
  }
  if (lastDigit === 10 - (sum % 10)) {
    return validResult;
  }
  return invalidResult;
}

export function email(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  const validResult = { valid: true, message: "" };
  if (input === null || input === undefined || input === "") {
    return validResult;
  }
  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/.test(
      input.toString().trim(),
    )
  ) {
    return {
      valid: false,
      message: localization.email,
    };
  }

  return validResult;
}
export function login(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  const validResult = { valid: true, message: "" };
  if (input === null || input === undefined || input === "") {
    return validResult;
  }
  if (!/^[A-Za-z\d]{8,}$/.test(input.toString())) {
    return {
      valid: false,
      message: localization.login,
    };
  }

  return validResult;
}
export function password(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  const validResult = { valid: true, message: "" };
  if (input === null || input === undefined || input === "") {
    return validResult;
  }
  if (!/^.*[a-ząćęłńóśźż]/.test(input.toString())) {
    return {
      valid: false,
      message: localization.passwordLeastOneLowercaseLetter,
    };
  }
  if (!/^.*[A-ZĄĆĘŁŃÓŚŹŻ]/.test(input.toString())) {
    return {
      valid: false,
      message: localization.passwordLeastOneUppercaseLetter,
    };
  }
  if (!/^.*\d/.test(input.toString())) {
    return {
      valid: false,
      message: localization.passwordLeastOneNumber,
    };
  }
  if (!/^.*[@$!%*?&+-,.:;<>(){}[\]#^/|!?'"\\_]/.test(input.toString())) {
    return {
      valid: false,
      message: localization.passwordLeastOneSpecialCharacter,
    };
  }
  if (!(input.toString().length > 9)) {
    return {
      valid: false,
      message: localization.passwordMinTenCharacters,
    };
  }
  return validResult;
}

export function passwordsTheSame(
  password1: string | undefined,
  password2: string | undefined,
  invalidMessage: string,
): ValidationResult {
  if (
    password1 === password2 ||
    (password1 === "" && password2 === undefined) ||
    (password1 === undefined && password2 === "")
  ) {
    return {
      valid: true,
    };
  }
  return {
    valid: false,
    message: invalidMessage,
  };
}

export function minCharacters(
  minCharacters: number | undefined,
): ValidationFunction<string | number | undefined> {
  return (
    input: string | number | boolean | undefined,
    localization: Localization,
  ): ValidationResult => {
    if (
      minCharacters === undefined ||
      input === undefined ||
      input === "" ||
      input.toString().length >= minCharacters
    ) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      message: localization.minCharacters.replace(
        "{0}",
        minCharacters.toString(),
      ),
    };
  };
}

export function maxCharacters(
  maxCharacters: number | undefined,
): ValidationFunction<string | number | undefined> {
  return (
    input: string | number | boolean | undefined,
    localization: Localization,
  ): ValidationResult => {
    if (
      maxCharacters === undefined ||
      input === undefined ||
      input === "" ||
      input.toString().length <= maxCharacters
    ) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      message: localization.maxCharacters.replace(
        "{0}",
        maxCharacters.toString(),
      ),
    };
  };
}

export function minNumber(
  minNumber: number | undefined,
): ValidationFunction<string | number | undefined> {
  return (
    input: string | number | boolean | undefined,
    localization: Localization,
  ): ValidationResult => {
    if (
      minNumber === undefined ||
      input === undefined ||
      input === "" ||
      (!isNaN(Number(input)) && Number(input) >= minNumber)
    ) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      message: localization.minNumber.replace("{0}", minNumber.toString()),
    };
  };
}

export function maxNumber(
  maxNumber: number | undefined,
): ValidationFunction<string | number | undefined> {
  return (
    input: string | number | boolean | undefined,
    localization: Localization,
  ): ValidationResult => {
    if (
      maxNumber === undefined ||
      input === undefined ||
      input === "" ||
      (!isNaN(Number(input)) && Number(input) <= maxNumber)
    ) {
      return {
        valid: true,
      };
    }
    return {
      valid: false,
      message: localization.maxNumber.replace("{0}", maxNumber.toString()),
    };
  };
}

export function maxDecimalPlaces(
  maxDecimal: number | undefined,
): ValidationFunction<string | number | undefined> {
  return (
    input: string | number | boolean | undefined,
    localization: Localization,
  ): ValidationResult => {
    if (
      maxDecimal === undefined ||
      input === undefined ||
      input === "" ||
      isNaN(Number(input))
    ) {
      return {
        valid: true,
      };
    }
    const inputString = input.toString();
    const decimalPlaces = inputString.includes(".")
      ? inputString.split(".")[1].length
      : 0;

    const valid = decimalPlaces <= maxDecimal;
    if (valid) {
      return {
        valid: true,
      };
    } else {
      return {
        valid: false,
        message: localization.maxDecimalPlaces.replace(
          "{0}",
          maxDecimal.toString(),
        ),
      };
    }
  };
}

export function youtubeLink(
  input: string | number | boolean | undefined,
  localization: Localization,
): ValidationResult {
  const validResult = { valid: true, message: "" };
  if (input === null || input === undefined || input === "") {
    return validResult;
  }
  if (
    !/^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(?!.*\/channel\/)(?!\/@)(.+)?$/.test(
      input.toString(),
    )
  ) {
    return {
      valid: false,
      message: localization.youtube,
    };
  }

  return validResult;
}

export function time(
  input: string | number | undefined | null,
  localization: Localization,
): ValidationResult {
  const validResult = { valid: true, message: "" };
  if (input === null || input === undefined || input === "") {
    return validResult;
  }
  if (!TIME_REGEXP.test(input.toString())) {
    return {
      valid: false,
      message: localization.time,
    };
  }

  return validResult;
}

export function minTime(
  minTimeConstraint: string | undefined,
): ValidationFunction<string | number | undefined> {
  return (
    input: string | number | undefined | null,
    localization: Localization,
  ): ValidationResult => {
    if (
      input === undefined ||
      input === null ||
      input === "" ||
      minTimeConstraint === undefined ||
      minTimeConstraint === ""
    ) {
      return {
        valid: true,
      };
    }
    const minTimeParsed = parseTime(minTimeConstraint);
    const inputParsed = parseTime(input.toString());

    if (minTimeParsed === undefined || inputParsed === undefined) {
      return {
        valid: false,
        message: localization.time,
      };
    }

    if (minTimeParsed[0] < inputParsed[0]) {
      return {
        valid: true,
      };
    }

    if (
      minTimeParsed[0] === inputParsed[0] &&
      minTimeParsed[1] <= inputParsed[1]
    ) {
      return {
        valid: true,
      };
    }

    return {
      valid: false,
      message: localization.minTime.replace("{0}", minTimeConstraint),
    };
  };
}

export function maxTime(
  maxTimeConstraint: string | undefined,
): ValidationFunction<string | number | undefined> {
  return (
    input: string | number | undefined | null,
    localization: Localization,
  ): ValidationResult => {
    if (
      input === undefined ||
      input === null ||
      input === "" ||
      maxTimeConstraint === undefined ||
      maxTimeConstraint === ""
    ) {
      return {
        valid: true,
      };
    }
    const maxTimeParsed = parseTime(maxTimeConstraint);
    const inputParsed = parseTime(input.toString());

    if (maxTimeParsed === undefined || inputParsed === undefined) {
      return {
        valid: false,
        message: localization.time,
      };
    }

    if (maxTimeParsed[0] > inputParsed[0]) {
      return {
        valid: true,
      };
    }

    if (
      maxTimeParsed[0] === inputParsed[0] &&
      maxTimeParsed[1] >= inputParsed[1]
    ) {
      return {
        valid: true,
      };
    }

    return {
      valid: false,
      message: localization.maxTime.replace("{0}", maxTimeConstraint),
    };
  };
}

export function date(
  input: string | number | undefined | null,
  localization: Localization,
): ValidationResult {
  const validResult = { valid: true, message: "" };
  if (input === null || input === undefined || input === "") {
    return validResult;
  }
  if (!DATE_REGEXP.test(input.toString())) {
    return {
      valid: false,
      message: localization.date,
    };
  }

  return validResult;
}

export function valueFromList<
  T extends Record<
    string | number | symbol,
    object | string | number | boolean
  >,
>(list: T[], key: keyof T) {
  const valuesList = list.map((item) => item[key].toString());
  return function valueFromList(
    input: string | number | undefined,
    localization: Localization,
  ): ValidationResult {
    return {
      valid:
        input === undefined ||
        input === "" ||
        valuesList.includes(input.toString()),
      message: localization.valueFromList,
    };
  };
}
