import {
  type Ref,
  watch,
  computed,
  type ComputedRef,
  ref,
  inject,
  type WritableComputedRef,
  onMounted,
  onUnmounted,
} from "vue";
import type {
  FieldValidation,
  FormValidation,
  ValidationResult,
  ModelValidation,
} from "../types/formValidation";
import { localizationSymbol } from "./injectionSymbols";
import { defaultLocalization, type Localization } from "./validators";

export const useFieldValidation = <T>(
  id: string,
  input: Ref<T> | ComputedRef<T> | WritableComputedRef<T>,
  validators:
    | Ref<
        {
          (input: T, localization: Localization): ValidationResult;
        }[]
      >
    | ComputedRef<
        {
          (input: T, localization: Localization): ValidationResult;
        }[]
      >,
  modelValidators?: ModelValidation[],
  formValidation?: FormValidation,
  active = true,
): FieldValidation => {
  const initialized = ref<boolean>(false);

  const localization = inject(localizationSymbol, defaultLocalization);
  const validationResult = computed(() => {
    if (validators.value && initialized.value) {
      for (let i = 0; i < validators.value.length; i++) {
        const validatorResult = validators.value[i](input.value, localization);
        if (!validatorResult.valid) {
          return validatorResult;
        }
      }
    }

    if (modelValidators) {
      for (let i = 0; i < modelValidators.length; i++) {
        const validatorResult = modelValidators[i].validationResult.value;
        if (!validatorResult.valid && modelValidators[i].isInitialized()) {
          return validatorResult;
        }
      }
    }

    return { valid: true };
  });

  watch(validationResult, (oldValue, newValue) => {
    if (formValidation && newValue.valid !== oldValue.valid) {
      formValidation.validate(false);
    }
  });

  function initializeValidation() {
    initialized.value = true;
    if (modelValidators) {
      modelValidators.forEach((validator) => validator.initialize(id));
    }
  }

  function isInitialized() {
    return initialized.value;
  }

  function resetValidation() {
    initialized.value = false;
    if (modelValidators) {
      modelValidators.forEach((validator) =>
        validator.removeInitialization(id),
      );
    }
  }

  const fieldValidation = {
    id,
    validationResult,
    initializeValidation,
    resetValidation,
    isInitialized,
  };

  onMounted(() => {
    if (!active) {
      return;
    }
    if (formValidation) {
      formValidation.addFieldValidation(fieldValidation);
    }
    if (modelValidators) {
      modelValidators.forEach((validator) => validator.addField(id));
    }
  });

  onUnmounted(() => {
    if (formValidation) {
      formValidation.removeFieldValidation(fieldValidation);
    }
    if (modelValidators) {
      modelValidators.forEach((validator) => {
        validator.removeField(id);
      });
    }
  });

  return fieldValidation;
};
